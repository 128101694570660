/** @format */

import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";
import axios from "axios";
import cross from "../../../assets/admin/cross.svg";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import GradientSVG from "./GradientSVG";
import "./radialBar.css";
import UserPaymentHistory from "./UserPaymentHistory";

const backdrop = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const modal = {
  hidden: {
    y: "150px",
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const Batch = ({ showBatch, setShowBatch, batchId }) => {
  const [tSessions, setTSessions] = useState(15);
  const [aSessions, setASessions] = useState(10);
  const [percentage, setPercentage] = useState((aSessions / tSessions) * 100);
  const auth = JSON.parse(localStorage.getItem("auth"));
  const [batchdata, setBatchData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [attendanceDate,setAttendanceData]=useState([]);
  // stop propagation handler
  const stopPropagationHandler = (e) => {
    e.stopPropagation();
  };
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleUsernameClick = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  useEffect(() => {
    if (batchId) {
      fetchSchedules(batchId);
      fetchStudent(batchId);
    }
  }, [batchId]);

  const fetchSchedules = async (batchId) => {
    const id = batchId;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/singlebootcampsbatch/${id}`
      );
      console.log("Response",response.data);
      setBatchData(response.data.bootcampDetails);
      setAttendanceData(response.data.previousSession);
    } catch (err) {
      console.error(err.message);
    }
  };
  const fetchStudent = async (batchId) => {
    const id = batchId;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/studentRegsiterBootCamp/${id}`
      );
      setUserData(response.data.studentDetails);
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleSubmit = async (data) => {
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/payRem`, {
        data,
      });
      
    alert("Msg send success");
    } catch (error) {
    alert("error")
    } 
  };
  // connect to the chess server
  return (
    <>
      <GradientSVG
        startColor="#4FBAF0"
        endColor="#544C53"
        idCSS={"grad"}
        rotation={90}
      />
      <AnimatePresence>
        {showBatch && (
          <motion.div
            variants={backdrop}
            inherit="hidden"
            animate="visible"
            exit={{ opacity: 0 }}
            className="bg-[rgba(0,0,0,0.5)] h-screen w-full md:w-[97%] fixed top-0 left-0 md:left-16 z-30"
            onClick={() => setShowBatch(false)}
          >
            <motion.div
              onClick={stopPropagationHandler}
              variants={modal}
              hidden="hidden"
              visible="visible"
              style={{ display: "flex" }}
              className="bg-white h-full w-full overflow-y-scroll  flex-col justify-center rounded-2xl overflow-hidden z-30  bg-cover bg-center shadow-[0_8px_30px_rgb(0,0,0,0.12)] "
            >
              <>
                <div className="p-6 w-full px-2 font-poppins h-full flex">
                  <div
                    className={`flex flex-col w-full transition-all ${"w-full"}`}
                  >
                    <div className="flex justify-between px-8 my-2">
                      <div className="flex flex-col md:flex-row gap-3 md:gap-6">
                        <h1 className="text-3xl font-bold ">
                          {batchdata.batchType}
                        </h1>
                        <h1 className="text-3xl font-bold hidden md:block">
                          |
                        </h1>
                        <h1 className="text-2xl md:text-3xl font-bold ">
                          {batchdata.timeSlot}
                        </h1>
                      </div>
                      <div
                        className="text-2xl cursor-pointer"
                        onClick={() => setShowBatch(false)}
                      >
                        <img src={cross} alt="" />
                      </div>
                    </div>
                    {batchdata.weeksDay ? (
                      <div className="text-xl text-[#7D7D7D] font-semibold px-8  md:px-12 py-2">
                        {batchdata.weeksDay.join("-")}
                      </div>
                    ) : (
                      <div className="text-xl text-[#7D7D7D] font-semibold px-8  md:px-12 py-2">
                        Data not found
                      </div>
                    )}

                    <div className="flex md:flex-row flex-col w-screen justify-evenly my-8 gap-">
                      <div className="flex flex-col md:w-[45%] shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-6  rounded-lg transition-all ">
                        <h1 className=" text-[#000] text-lg font-semibold xl:text-2xl ">
                          Student details
                        </h1>
                        <div className="flex flex-col py-4 rounded text-sm   ">
                          <div className="flex flex-row md:gap-0 gap-4 text-[#3796E9] w-1/7 md:w-full justify-between border-b  border-[#3796E9] py-2 px-4">
                            <div className="md:w-1/3 md:text-center">Name</div>
                            <div className="md:w-1/3 md:text-center">City</div>

                            <div className="md:w-1/3 md:text-center">
                              Status
                            </div>
                            <div className="md:w-1/3 md:text-center">
                              PayRemainder
                            </div>
                          </div>
                          <div className="overflow-y-scroll min-h-[17rem]   ">
                            {userData.map((item, index) => (
                              <div className="" key={item._id}>
                                <div className="flex flex-row  w-full justify-between border-b  py-2 px-4 cursor-pointer  ">
                                  <div className="md:w-1/3 md:text-center relative     justify-center ">
                                    {new Date(
                                      item.createdAt
                                    ).toLocaleDateString()}
                                  </div>
                                  <div   onClick={() => handleUsernameClick(item)} className="md:w-1/3 md:text-center hover:underline cursor-pointer">
                                    {item.username}
                                  </div>
                                  <div className="md:w-1/3 md:text-center">
                                    {item.payStatus}
                                  </div>
                                  <div onClick={(e) => {
                    e.stopPropagation();
                    handleSubmit(item);
                  }} className="md:w-1/3 md:text-center bg-primary p-1 text-white rounded">
                                    Payment Remainder
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col md:w-[45%] shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-6  rounded-lg transition-all ">
                        <h1 className=" text-[#000] text-lg font-semibold xl:text-2xl ">
                          Attendance details
                        </h1>
                        <div className="flex flex-col py-4 rounded text-sm   ">
                          
                          <div className="flex flex-row md:gap-0 gap-4 text-[#3796E9] w-1/7 md:w-full justify-between border-b  border-[#3796E9] py-2 px-4">
                            <div className="md:w-1/5 md:text-center">Session No</div>
                            <div className="md:w-1/5 md:text-center">
Date
                            </div>
                            <div className="md:w-1/5 md:text-center">
                            TimeSlot
                            </div>
                            <div className="md:w-1/5 md:text-center">
                            Status
                            </div>
                            <div className="md:w-1/5 md:text-center">
                              No. attendees
                            </div>
                          </div>
                          { attendanceDate? (
                          attendanceDate.map((session) => (
                          <div key={session.sessionNumber}  className=" overflow-y-scroll">
                            <div className="flex flex-row mx-0 w-full justify-between border-b  py-2 px-4 cursor-pointer ">
                              <div className="md:w-1/5 md:text-center relative     justify-center ">
                              {session.sessionNumber}
                              </div>
                              <div className="md:w-1/5 md:text-center relative     justify-center ">
                              {session.formattedDate}
                              </div>
                              <div className="md:w-1/5 md:text-center relative     justify-center ">
                              {session.timeSlot}
                              </div>
                              <div className="md:w-1/5 md:text-center hover:underline cursor-pointer">
                              {session.status}

                              </div>
                              <div className="md:w-1/5 md:text-center">
                              {session.totalStudentPresent}/{session.totalStudent}
                              </div>
                            </div>
                          </div>
                           )))
                          :(
                            <div>

                              </div>
                          )} 
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row w-screen justify-evenly my- gap-">
                      <div className="flex flex-col md:w-[45%] shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-6  rounded-lg transition-all ">
                        <h1 className=" text-[#000] text-lg font-semibold xl:text-2xl ">
                          Upcoming class
                        </h1>
                        <div className="flex flex-col py-4 rounded text-sm   ">
                          <div className="flex flex-row md:gap-0 gap-4 text-[#3796E9] w-1/7 md:w-full justify-between border-b  border-[#3796E9] py-2 px-4">
                            <div className="md:w-1/3 md:text-center">Name</div>
                            <div className="md:w-1/3 md:text-center">City</div>

                            <div className="md:w-1/3 md:text-center">
                              Status
                            </div>
                          </div>
                          {/* {leadsData.map((item, index) => ( */}
                          <div className=" overflow-y-scroll min-h-[13rem]">
                            <div className="flex flex-row mx-0 w-full justify-between border-b  py-2 px-4 cursor-pointer ">
                              <div className="md:w-1/3 md:text-center relative     justify-center ">
                                21 May
                              </div>
                              <div className="md:w-1/3 md:text-center hover:underline cursor-pointer">
                                Big Chungus
                              </div>
                              <div className="md:w-1/3 md:text-center">
                                Paris
                              </div>
                            </div>
                          </div>
                          {/* ))} */}
                        </div>
                      </div>
                      <div className="flex flex-col md:w-[45%] shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-6  rounded-lg transition-all min-h-[13rem]">
                        <div className=" flex flex-col gap-2 ">
                          <div className="mx-0 my-2 px-6 py-2">
                            <h1 className=" text-[#085398] text-lg font-semibold xl:text-2xl ">
                              Coach attendance
                            </h1>
                            <div className="flex justify-between">
                              <div className="w-[25%] flex items-center justify-center relative">
                                <div className="absolute items-center flex flex-col justify-center text-[#114A82]">
                                  <h1 className="text-xs">Coach attendance</h1>
                                  <h2 className="text-xl truncate ">
                                    {String(percentage).slice(0, 5)} %
                                  </h2>
                                </div>
                                <CircularProgressbar
                                  value={percentage}
                                  styles={buildStyles({
                                    rotation: -0.5,
                                  })}
                                  className="py-2"
                                />
                              </div>
                              <div className=" flex flex-col items-end text-xs gap-2 xl:justify-evenly xl:text-sm">
                                <div className="flex whitespace-nowrap  gap-1 ">
                                  Total Session :
                                  <div className="text-[#114A82] font-semibold">
                                    {tSessions}
                                  </div>
                                </div>
                                <div className="flex whitespace-nowrap  gap-1 ">
                                  Session Left :
                                  <div className="text-[#114A82] font-semibold">
                                    {aSessions}
                                  </div>
                                </div>
                                <div className="flex whitespace-nowrap gap-1  ">
                                  Session Attended :
                                  <div className="text-[#114A82] font-semibold">
                                    {aSessions}
                                  </div>
                                </div>
                                <div className="flex whitespace-nowrap  gap-1 ">
                                  Session Missed :
                                  <div className="text-[#114A82] font-semibold">
                                    {aSessions}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <UserPaymentHistory
        user={selectedUser}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </>
  );
};

export default Batch;
