import React, { useEffect, useState } from "react";
import misaki from "../../../../assets/user.svg";
import { Link, useNavigate } from "react-router-dom";

import { useGlobalContext } from "../../../../utils/contexts";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import att from "../../../../assets/Dashboard/iconoir_book.svg";
import hw from "../../../../assets/Dashboard/tabler_chess.svg";
import rank from "../../../../assets/Dashboard/iconoir_leaderboard.svg";
import ph from "../../../../assets/Dashboard/ph_trophy.svg";
import first from "../../../../assets/Dashboard/twemoji_1st-place-medal.svg";
import second from "../../../../assets/Dashboard/twemoji_2nd-place-medal.svg";
import third from "../../../../assets/Dashboard/twemoji_3rd-place-medal.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import clk from "../../../../assets/Dashboard/mdi_clock-outline.svg";
import sen from "../../../../assets/Dashboard/send.svg";
import master from "../../../../assets/Dashboard/Mastercard Logo.svg";
import visa from "../../../../assets/Dashboard/Visa.svg";
import pay from "../../../../assets/Dashboard/PayPal.svg";
import bhim from "../../../../assets/Dashboard/Bhim Upi.svg";
import wLock from "../../../../assets/Dashboard/lock-white.svg";
import gLock from "../../../../assets/Dashboard/gLock.svg";
import { attendanceSuccess, errorleaveSubmitted, errorUpdatingAttendance, leaveSubmitted } from "../../../../utils/notifications";
import { useParams } from "react-router-dom";
import LeaveRequestModal from "./LeaveRequestModal";

const CoachDemoDashboard = () => {
  // toggle locked to switch between locked and unlocked states
  const { demoId } = useParams();
  const [locked, setLocked] = useState("two");
  const { setAuth } = useGlobalContext();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate(); 
  const auth = JSON.parse(localStorage.getItem("auth"));
  const [demoStatus, setDemoStatus] = useState("");
  const [demoDetail, setDemoDetail] = useState("");
  const [batchStatus, setBatchStatus] = useState("");
  const [batchDetail, setBatchDetail] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null);
  const [daysRemaining, setDaysRemaining] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [topics, setTopics] = useState([]);
  const [users, setUsers] = useState([]);
  const [markAttendance, setMarkAttendance] = useState(false);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [bootcampSchedules, setBootcampSchedules] = useState([]);
  const { coachData } = useGlobalContext();
  const [bootId, setBootId] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState("");
  const [attendanceData, setAttendanceData] = useState({});
  const [studentAttendance,setStudentAttendance]=useState([]);
  const [previousSession, setPreviousSession] = useState(null);
  const [upcomingSession, setUpcomingSession] = useState(null);
  const [missedAttendanceSessions, setMissedAttendanceSessions] = useState(null);
  const [sessionDates, setSessionDates] = useState([]); // Add this line
  const [bootcamp, setBootcamp] = useState(null); // Add this line
  const [selectedSession, setSelectedSession] = useState(null); 
  const [customTopic, setCustomTopic] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [allLeaveData,setAllLeaveData]=useState("");
  const [leaveData, setLeaveData] = useState({
    date: "",
    reason: "",
    description: "",
  });

  const handleChange = (e) => {
    setLeaveData({ ...leaveData, [e.target.name]: e.target.value });
  };

  const handleSubmitLeave = async () => {
    try {
      const coachId=coachData?._id;
      const payload = {
        ...leaveData,
        demoId,
        coachId,
      };
      await axios.post( `${process.env.REACT_APP_BACKEND_URL}/api/createLeaveRequest`,  payload);
     leaveSubmitted();
      setShowModal(false); // Close the modal after successful submission
      setLeaveData({ date: "", reason: "", description: "" }); // Reset form
    } catch (error) {
      console.error("Error submitting leave request:", error);
     errorleaveSubmitted();
    }
  };


//   const fetchDemoData = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_BACKEND_URL}/api/checkExistingDemoByPhoneEmail`,
//         {
//           headers: {
//             Authorization: `Bearer ${auth?.accessToken}`,
//           },
//         }
//       );

//       if (response.data.success) {
//         setDemoStatus(response.data.userStatus);
//         setDemoDetail(response.data.demoClassDetail);
//       } else {
//         console.log("Error in fetching data");
//       }
//     } catch (error) {
//       console.error("Error fetching demo data", error);
//       setError("An error occurred while fetching data");
//     } finally {
//       setLoading(false);
//     }
//   };
  
  useEffect(() => {
    const initialAttendanceData = {};
    users.forEach((user) => {
      initialAttendanceData[user.leadId] = "A"; // Set default attendance to 'A' (Absent)
    });
    setAttendanceData(initialAttendanceData);
  }, [users]);
  const fetchBatchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/existingUserBatchStatus`,
        {
          headers: {
            Authorization: `Bearer ${auth?.accessToken}`,
          },
        }
      );

      if (response.data.success) {
        setBatchStatus(response.data.userBatchStatus);
        setBatchDetail(response.data.batchDetails);
      } else {
        console.log("Error in fetching data");
      }
    } catch (error) {
      console.error("Error fetching demo data", error);
      setError("An error occurred while fetching data");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const calculateDaysRemaining = (startDate) => {
      const currentDate = new Date();
      const start = new Date(startDate);
      const timeDifference = start - currentDate;
      return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    };

    if (demoDetail?.startDate) {
      setDaysRemaining(calculateDaysRemaining(demoDetail.startDate));
    } else if (batchDetail?.startDate) {
      setDaysRemaining(calculateDaysRemaining(batchDetail.startDate));
    }
  }, [demoDetail, batchDetail]);

  useEffect(() => {
    fetchBatchData();
    // fetchDemoData();
   
  }, [auth?.accessToken, locked]);

  const fetchLeaveRequest = async () => { 
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/getAllLeaveRequest/${coachData?._id}/${demoId}`
      );
  
    console.log("response",response.data);
      
  
      setAllLeaveData(response.data);
      
    } catch (error) {
      console.error("Error fetching schedule:", error);
    }
  };
  useEffect(()=>{
    if(coachData?._id){
      fetchLeaveRequest();
    }
    },[coachData])
  useEffect(() => {
    if (batchStatus?.payStatus === "Completed") {
      setLocked("one");
    } else if (batchStatus) {
      setLocked("three");
    } else {
      setLocked("two");
    }
  }, [auth?.accessToken, locked, batchStatus]);

  const calculateCoveredTopics = () => {
    if (!startDate || topics.length === 0) return [];

    const today = new Date(); // Get today's date
    return topics.filter((topic, index) => {
      const topicCoveredDate = new Date(startDate);
      topicCoveredDate.setDate(startDate.getDate() + index * 2); // Calculate the coverage date for alternate days
      return today >= topicCoveredDate; // Check if it's covered by today
    });
  };

  // const filteredTopicPrevious = topics.find(topic => topic.TopicId === previousSession?.sessionNumber);
  const filteredTopicUpcoming = topics.find(topic => topic.TopicId === upcomingSession?.sessionNumber);
  const fetchBootcampSchedule = async () => { 
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/getRegisterUserDemo/${coachData?._id}/${demoId}`
      );
  
      if (!response.ok) {
        console.error("Failed to fetch bootcamp schedules:", response.statusText);
        return;
      }
  
    //   const { bootcamp, previousSession, upcomingSession, missedAttendanceSessions ,sessionDates} = await response.json();
  
    const {bootcamp}=await response.json();
    //   setBootId(bootcamp._id);
      setDemoDetail(bootcamp);
    //   setPreviousSession(previousSession);
    //   setUpcomingSession(upcomingSession);
    //   setSessionDates(sessionDates)
  
    //   if (missedAttendanceSessions.length > 0) {
    //     console.log("Sessions with missing attendance:", missedAttendanceSessions);
    //     setMissedAttendanceSessions(missedAttendanceSessions); // Assuming you have a state for this
    //   }
    } catch (error) {
      console.error("Error fetching schedule:", error);
    }
  };
  useEffect(() => {
  
   
    const fetchTopics = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/topic`
        );
        const Topicdata = await response.json();
        setTopics(Topicdata); // Set the list of topics
      } catch (error) {
        console.error("Error fetching topics:", error);
      }
    };
    
    if(coachData?._id){

      fetchBootcampSchedule(); // Fetch the start date
    }


     
   
     
  
    fetchTopics(); // Fetch the topics
 
  }, [coachData?._id]);

 
  useEffect(() => {
    const fetchUsers = async () => {
      if (bootId) {
        try {
         
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/specificBatchUser/${bootId}`
          );
          const Userdata = await response.json();
          if (Userdata.allStudents && Array.isArray(Userdata.allStudents)) {
            setUsers(Userdata.allStudents);
          } else {
            console.error("Unexpected response format: ", Userdata);
            setUsers([]);
          }
        } catch (error) {
          console.error("Error fetching users:", error);
        }
      }
    };
  
    fetchUsers();
  }, [bootId])
  
  useEffect(()=>{
    if(bootId){
      fetchStudentAttendance();
    }
  },[bootId])

  const fetchStudentAttendance=async()=>{
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/getAllBootCampAttendance/${bootId}`
      );
      setStudentAttendance(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }

  const handleSelectChange = (event) => {
    const selectedTopicId = event.target.value; // Get the value of the selected option
  
    // Check if the selected value is valid and not already in the array
    if (selectedTopicId && !selectedTopics.includes(selectedTopicId)) {
      setSelectedTopics([...selectedTopics, selectedTopicId]); // Add the new value to the array
    }
  };
  


  const handleTopicChange = (e) => {
    const value = e.target.value;
    setSelectedTopic(value);
    // Clear custom topic when a predefined topic is selected
    if (value !== "custom") {
      setCustomTopic("");
    }
  };
  const handleCustomTopicChange = (e) => {
    setCustomTopic(e.target.value);
  };
  

  const handleAttendanceChange = (e, leadId) => {
    setAttendanceData((prevState) => {
      const attendanceValue = e.target.checked ? "P" : "A"; // 'P' if checked, 'A' if unchecked
      return {
        ...prevState,
        [leadId]: attendanceValue, // Update attendance only based on userId
      };
    });
  };
  
  const handleSubmitAttendance = async () => {
    // Map through the attendanceData to format it as needed
    let formattedAttendanceData = users.map((user) => ({
      leadId: user.leadId, // Use userId for userId in the final data
      username: user.username, // Include the username
      status: attendanceData[user.leadId] || "A", 
    }));
  
    const topicId = selectedTopic === "custom" ? customTopic : selectedTopic;
  
    const attendance = {
      bootcampId: bootcampSchedules._id,
      sessionId: selectedSession.sessionNumber,
      topicId: topicId,
      coachId: coachData,
      date: selectedSession.formattedDate,
      attendanceData: formattedAttendanceData, // Correctly formatted attendance data
    };
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/addAttendance`,
        attendance
      );
  
      if (response.status === 201) {
        fetchStudentAttendance();
        fetchBootcampSchedule();
        attendanceSuccess(); // Handle success

        setSelectedSession(null);
        setAttendanceData({}); 
       
        setSelectedTopic("");
        setCustomTopic(
          ""
        )
      } else {
        errorUpdatingAttendance();
        console.error("Error:", response.data);
      }
    } catch (error) {
      errorUpdatingAttendance();
      console.error("Error submitting attendance:", error);
    }
  };
  

  
  return (
    <>
      <section
        className={`flex flex-col justify-center items-center bg-white text-black font-poppins lg:px-4 py-8 ${
          isOpen ? "overflow-hidden" : ""
        } `}
      >
        <div className="flex flex-col w-[95%] gap-4 ">
          <div className="rounded-xl shadow-[0_3px_10px_rgb(0,0,0,0.2)] px-2 py-2 bg-userDash bg-center min-h-[15rem] flex">
            <div className="md:w-1/2 w-full flex flex-col  ">
              <div className="flex p-2 ">
                <div className="w-[5rem] h-[5rem] mm:w-[6rem] mm:h-[6rem] lg:w-[11rem] lg:h-[11rem] flex mx-4  items-center justify-center rounded-full overflow-hidden">
                  <img
                    className="h-full w-full object-cover"
                    // src={!auth?.profilePic ? misaki : auth?.profilePic}
                    src={misaki}
                    alt=""
                  />
                </div>
                <div className="w-1/2  mm:w-4/6  flex flex-col justify-center   mm:items-start gap-1 ">
                  <h1 className="text-black text-xl xl:text-4xl font-medium first-letter:uppercase">
                    {auth?.name ? auth.name : "unknown"}
                 
                  </h1>
                  <h2 className="flex gap-2 lg:text-base opacity-70 text-xs font-medium whitespace-nowrap">
                    {locked === "one" ? (
                      <>
                        <div className="text-[#298BD0] bg-[#BCE0FA] px-5 lg:px-4 py-1 rounded">
                          Student
                        </div>
                        {batchDetail ? (
                          <div className="text-[#FAA500] bg-[#FFD583] px-5 lg:px-4 py-1 rounded">
                            {batchDetail.batchType}
                          </div>
                        ) : (
                          <div className="text-[#FAA500] bg-[#FFD583] px-5 lg:px-4 py-1 rounded">
                            Null
                          </div>
                        )}

                        <div className="text-[#39DD00] bg-[#A1FC81] px-5 lg:px-4 py-1 rounded">
                          Top 14
                        </div>
                      </>
                    ) : locked === "two" ? (
                      <>
                        {/* <div className="text-[#FE4E00] bg-[#FEA983] px-5 lg:px-4 py-1 rounded">
                          Coach
                        </div>
                        <div className="text-[#FE4E00] bg-[#f8d999] px-5 lg:px-4 py-1 rounded">
                          2 YR
                        </div> */}
                      </>
                    ) : (
                      <>
                        {batchDetail ? (
                          <div className="text-[#FAA500] bg-[#FFD583] px-5 lg:px-4 py-1 rounded">
                            {batchDetail.batchType}
                          </div>
                        ) : (
                          <div className="text-[#FAA500] bg-[#FFD583] px-5 lg:px-4 py-1 rounded">
                            Null
                          </div>
                        )}
                      </>
                    )}
                  </h2>

                  {/* <h3 className="text-[#676767]">User ID - 123456789</h3> */}
                  {locked === "three" ? (
                    <>
                      <div className="flex flex-col gap-2">
                        <div className="text-[#FE4E00] bg-[#FEA983] px-5 lg:px-4 py-1 rounded">
                          Pay now to conform your seat
                        </div>
                        <div
                          className="flex w-[20%] justify-center items-center rounded-full bg-[#298BD0] text-white cursor-pointer py-0.5"
                          onClick={() => navigate("/orders/batch")}
                        >
                          Pay
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {locked === "one" ? (
                <>
                  {/* <div className="hidden lg:flex gap-4  p-2 px-16 ">
                    <div className="bg-[#F2F2F2] flex flex-col p-4 rounded-lg ">
                      <h1 className="text-[#0169C2]">Class Attendance</h1>
                      <div className="flex gap-4 text-[#444444] ">
                        <img src={att} alt="" />
                        <div className="flex justify-evenly gap-5 ">
                          <p>11</p>
                          <p className="text-[#C6C6C6]">|</p>
                          <p>26</p>
                        </div>
                      </div>
                    </div>
                    <div className="bg-[#F2F2F2] flex flex-col p-4 rounded-lg ">
                      <h1 className="text-[#0169C2]">Homework</h1>
                      <div className="flex gap-4 text-[#444444] ">
                        <img src={hw} alt="" />
                        <div className="flex justify-evenly gap-5 ">
                          <p>30</p> <p className="text-[#C6C6C6]">|</p>
                          <p>36</p>
                        </div>
                      </div>
                    </div>
                    <div className="bg-[#F2F2F2] flex flex-col p-4 rounded-lg ">
                      <h1 className="text-[#0169C2]">Rank in batch</h1>
                      <div className="flex gap-4 text-[#444444] ">
                        <img src={rank} alt="" />
                        <div className="flex justify-evenly gap-5 ">
                          <p>#8</p> <p className="text-[#C6C6C6]">|</p>
                          <p>20</p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </>
              ) : locked === "three" ? (
                <></>
              ) : (
                <></>
              )}
            </div>
            {locked === "one" || locked === "three" ? (
              <>
                {" "}
                <div className="hidden w-1/2 md:flex flex-col items-center justify-center">
                  <h1 className="font-bold text-2xl text-[#298BD0]">
                    {batchDetail ? (
                      batchDetail.batchType | batchDetail.timeSlot
                    ) : (
                      <div>Data not found</div>
                    )}
                  </h1>
                  {batchDetail ? (
                    <h1 className="font-bold text-xl text-[#7D7D7D]">
                      {batchDetail.weeksDay.join(" - ")}
                    </h1>
                  ) : (
                    <h1 className="font-bold text-xl text-[#7D7D7D]">
                      DAta not found
                    </h1>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>

          <div className="w-full flex flex-col lg:flex-row px-2 py-2 gap-6">
            <div className="flex flex-col lg:w-[30%]  gap-6">
              {locked === "one" ? (
                <>
                  <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-4 rounded-xl">
                    <div className="flex justify-between">
                      <h1 className="font-medium text-xl lg:text-2xl ">
                        Uppcoming Class
                      </h1>
                      <div className="text-sm text-[#076CB3] bg-[#8FC7EE] rounded-full px-4 py-1 flex items-center">
                        23 June 2024
                      </div>
                    </div>
                    <div className="flex flex-col my-2 gap-1 ">
                      <h1 className="text-[#2586D9]">Session : 16</h1>
                      <h1 className="text-[#545454]">Chapter 7 : Pawn Mate</h1>
                      <div>
                        <button className="flex px-8 py-1.5 bg-[#298BD0] text-white rounded-full">
                          Join
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ) : locked === "two" ? (
                <>
                  {demoDetail ? (
                    <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-4 rounded-xl">
                      <div className="flex justify-between">
                        <h1 className="font-medium text-xl lg:text-2xl ">
                          Demo Class
                        </h1>
                        <div className="text-sm text-[#076CB3] bg-[#8FC7EE] rounded-full px-4 py-1">
                          {new Date(demoDetail.startDate).toLocaleDateString()}
                        </div>
                      </div>
                      <div className="flex flex-col my-2 gap-1 ">
                        <h1 className="text-[#2586D9]">{demoDetail.name}</h1>
                        <h1 className="text-[#545454]">
                          {demoDetail.timeSlot}
                        </h1>
                        <div>
                          <button className="flex px-8 py-1.5 bg-[#298BD0] text-white rounded-full">
                            Join
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                  {missedAttendanceSessions && missedAttendanceSessions.length > 0 && (
  <div>
   {missedAttendanceSessions.map((session, index) => {
  // Find the topic for the current session
  const filteredTopicPrevious = topics.find(topic => topic.TopicId === session.sessionNumber);

  return (
    <div
      className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-4 rounded-xl"
      key={index}
    >
      <div className="flex justify-between">
        <h1 className="font-medium text-xl lg:text-2xl ">Previous Class</h1>
        <div className="text-sm text-[#076CB3] bg-[#8FC7EE] rounded-full px-4 py-1">
          {session.formattedDate}
        </div>
      </div>
      <div>
        <h1 className="text-[#2586D9]">Session {session.sessionNumber}</h1>
        <h1 className="text-[#545454]">
          Chapter: {filteredTopicPrevious ? filteredTopicPrevious.TopicTitle : "No topic available"}
        </h1>
        <div>
          <button
            className="mt-4 flex px-8 py-1.5 bg-[#298BD0] text-white rounded-full"
            onClick={() => setSelectedSession(session)} // Update selected session
          >
            Fill details
          </button>
        </div>
      </div>
    </div>
  );
})}

  </div>
)}
                   

                      <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-4 rounded-xl">
                        <div className="flex justify-between">
                          <h1 className="font-medium text-xl lg:text-2xl ">
                            Upcoming Class
                          </h1>
                          <div className="text-sm text-[#076CB3] bg-[#8FC7EE] rounded-full px-4 py-1">
                          {upcomingSession ? upcomingSession.formattedDate : "No upcoming session"}

                          </div>
                        </div>
                        <div>
                          <h1 className="text-[#2586D9]">Session {upcomingSession ? upcomingSession.sessionNumber : "No upcoming session"}</h1>
                          <h1 className="text-[#545454]">
                          Chapter : {filteredTopicUpcoming ? filteredTopicUpcoming.TopicTitle : "No topic available"}
                          </h1>
                          <div>
                            <button className="mt-4 flex px-8 py-1.5 bg-[#298BD0] text-white rounded-full">
                              Join
                            </button>
                          </div>
                        </div>
                      </div>
                      {markAttendance && (
                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                          <div className="bg-white shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-4 rounded-xl mt-4 max-h-80 overflow-y-auto w-[90%] max-w-lg">
                            {/* Modal Header */}
                            <div className="flex justify-between items-center">
                              <div>
                                <h3 className="font-medium text-xl lg:text-2xl">
                                  Date
                                </h3>
                                <h1 className="font-medium text-xl lg:text-2xl">
                                  Session {/* Add session data here */}
                                </h1>
                              </div>
                              <div className="ml-4">
                                <select
                                  className="w-full text-center"
                                  onChange={handleSelectChange}
                                >
                                   <option value="" disabled>
          Select Topics
        </option>
                                  {topics.map((topic) => (
                                    <option key={topic._id} value={topic._id}>
                                      {topic.TopicTitle}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {/* Close Button */}
                              <button
                                className="text-xl font-semibold text-gray-500 hover:text-gray-700"
                                onClick={() => setMarkAttendance(false)} // Add your state handler to close the modal
                              >
                                &times;
                              </button>
                            </div>

                            {/* Modal Body */}
                            <div className="mt-4">
                              <div className="flex font-semibold max-h-100 overflow-y-auto">
                                <div className="w-1/2 text-[#298BD0]">
                                  Student
                                </div>
                                <div className="w-1/2 text-[#298BD0]">
                                  Attendance
                                </div>
                              </div>
                              {users &&
                                users.map((user) => (
                                  <div
                                    className="flex flex-col md:flex-row w-1/4 mx-2 md:mx-0 md:w-full justify-between border-b py-2 px-4 cursor-pointer"
                                    key={user._id}
                                  >
                                    <div className="w-1/2">
                                      {user.studentName}
                                    </div>
                                    <div className="w-1/2">
                                      {/* Attendance Input or Display */}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : locked === "three" ? (
                <>
                  {batchDetail && (
                    <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-4 rounded-xl">
                      <div className="flex justify-between">
                        <h1 className="font-medium text-xl lg:text-2xl ">
                          Upcoming Class
                        </h1>
                        <div className="text-sm text-[#076CB3] bg-[#8FC7EE] rounded-full px-4 py-1 flex items-center">
                          {new Date(batchDetail.startDate).toLocaleDateString()}
                        </div>
                      </div>
                      <div className="flex flex-col my-2 gap-1 ">
                        <h1 className="text-[#2586D9]">
                          {batchDetail.batchType} : 1
                        </h1>
                        <h1 className="text-[#545454]">Chapter 1 : Pawns</h1>
                        <div className="relative top-4">
                          <button className="flex px-4 py-1 bg-[#298BD0] text-white rounded-full items-center">
                            Join <img src={wLock} alt="" />
                          </button>
                          <p className="text-xs py-1">
                            *To join batch kindly complete the payment{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
              {locked === "one" ? (
                <>
                  <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-4 flex items-center rounded-xl">
                    <img className="w-[20%] " src={ph} alt="" />
                    <div className="text-center text-base lg:text-xl lg:px-2 font-bold  w-[60%]  bg-gradient-to-r from-[#F57B09] to-[#3292C5] inline-block text-transparent bg-clip-text ">
                      Sunday Tournament is live now
                    </div>
                    <div className=" w-[20%] flex flex-col items-center">
                      <h1 className="text-[#298BD0] underline underline-[#298BD0] cursor-pointer">
                        JOIN
                      </h1>
                      <p>IST 13:20</p>
                    </div>
                  </div>
                  <div className="flex justify-between  p-1">
                    <div className="w-[48%] shadow-[0_3px_10px_rgb(0,0,0,0.2)] gap-2 px-4 py-4 rounded-xl">
                      <h1 className="text-lg lg:text-xl font-medium">
                        Attendance
                      </h1>
                      <div className="flex items-end justify-between">
                        <h1 className="text-[#0776C5] text-2xl">79%</h1>
                        <p className="text-[#6E6E6E]">26 out of 35</p>
                      </div>
                    </div>
                    <div className="w-[48%] shadow-[0_3px_10px_rgb(0,0,0,0.2)] gap-2 px-4 py-4 rounded-xl">
                      <h1 className="text-lg lg:text-xl font-medium">
                        Homework
                      </h1>
                      <div className="flex items-end justify-between">
                        <h1 className="text-[#0776C5] text-2xl">89%</h1>
                        <p className="text-[#6E6E6E]">30 out of 35</p>
                      </div>
                    </div>
                  </div>
                  <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] flex flex-col pb-6 mt-4 rounded-xl">
                    <div className="">
                      <div className="flex relative w-full border border-b-black">
                        <h1 className="font-medium text-xl lg:text-2xl p-4 ">
                          Leaderboard
                        </h1>
                        <div className="absolute right-10 top-4">PTS</div>
                      </div>
                      <div className="flex items-center border py-2 px-6 justify-between border-b-black ">
                        <div className="flex items-center gap-4">
                          <img className="" src={first} alt="" />
                          <h1 className="text-[#545454]">Maestro</h1>
                        </div>
                        <p className="text-[#545454]">1500pts</p>
                      </div>
                      <div className="flex items-center border py-2 px-6 justify-between border-b-black ">
                        <div className="flex items-center gap-4">
                          <img className="" src={second} alt="" />
                          <h1 className="text-[#545454]">Ghost</h1>
                        </div>
                        <p className="text-[#545454]">1200pts</p>
                      </div>
                      <div className="flex items-center border py-2 px-6 justify-between border-b-black ">
                        <div className="flex items-center gap-4">
                          <img className="" src={third} alt="" />
                          <img className="" src={third} alt="" />
                          <h1 className="text-[#545454]">Iana</h1>
                        </div>
                        <p className="text-[#545454]">1000pts</p>
                      </div>
                      <div className="flex items-center border py-2 px-7 justify-between border-b-black ">
                        <div className="flex items-center gap-6">
                          <div className="text-[#545454]">4th</div>
                          <h1 className="text-[#545454]">Twitch</h1>
                        </div>
                        <p className="text-[#545454]">900pts</p>
                      </div>
                      <div className="flex items-center  py-2 px-7 justify-between bg-[#BFDDF3]  ">
                        <div className="flex items-center gap-6">
                          <div className="text-[#545454]">8th</div>
                          <h1 className="text-[#545454]">Kapkan</h1>
                        </div>
                        <p className="text-[#545454]">420pts</p>
                      </div>
                    </div>
                  </div>
                </>
              ) : locked === "two" ? (
                <>
                  {/* <div className=" shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-4 rounded-xl ">
                    <h1 className="font-medium text-xl lg:text-2xl text-black  ">
                      Attendence
                    </h1>
                    <div className="flex gap-5 md:gap-16">
                      <div className="flex w-[40%] items-center justify-center relative ml-5">
                        <div className="absolute items-center flex flex-col justify-center text-[#114A82]">
                          <h1 className="text-[#59AAD8] font-semibold">
                            Total
                          </h1>
                          <h1 className="text-[#665B5B] font-semibold">
                            Attendence
                          </h1>

                          <h3 className="text-[#59AAD8] text-4xl font-bold  ">
                            99%
                          </h3>
                        </div>
                        <CircularProgressbar
                          value={100}
                          styles={buildStyles({
                            rotation: -0.5,
                          })}
                          className="py-2"
                        />
                      </div>
                      <div className="flex w-[50%] mt-10 ">
                        <div className="">
                          <h3>Total Session:{bootcampSchedules.
totalSessionsPerWeek*4}
</h3>
                          <h3>Session Left:</h3>
                          <h3>Session Attended:</h3>
                          <h3>Session Missed:</h3>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </>
              ) : locked === "three" ? (
                <>
                  {" "}
                  <>
                    <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-4 flex items-center rounded-xl">
                      <img className="w-[20%] " src={ph} alt="" />
                      <div className="text-center text-base lg:text-xl lg:px-2 font-bold  w-[60%]  bg-gradient-to-r from-[#F57B09] to-[#3292C5] inline-block text-transparent bg-clip-text ">
                        Sunday Tournament is live now
                      </div>
                      <div className=" w-[20%] flex flex-col items-center">
                        <h1 className="text-[#298BD0] underline underline-[#298BD0] cursor-pointer flex justify-center">
                          <img className="w-1/2" src={gLock} alt="" />
                        </h1>
                        <p>IST 13:20</p>
                      </div>
                    </div>
                    <div className="flex justify-between  p-1">
                      <div className="w-[48%] shadow-[0_3px_10px_rgb(0,0,0,0.2)] gap-2 px-4 py-4 rounded-xl">
                        <h1 className="text-lg flex gap-2 lg:text-xl font-medium">
                          Attendance{" "}
                          <img className="w-[10%]" src={gLock} alt="" />
                        </h1>
                        <div className="flex items-end justify-between">
                          <h1 className="text-[#0776C5] text-2xl">0%</h1>
                          <p className="text-[#6E6E6E]">0 out of 0</p>
                        </div>
                      </div>
                      <div className="w-[48%] shadow-[0_3px_10px_rgb(0,0,0,0.2)] gap-2 px-4 py-4 rounded-xl">
                        <h1 className="text-lg flex gap-2 lg:text-xl font-medium">
                          Homework{" "}
                          <img className="w-[10%]" src={gLock} alt="" />
                        </h1>
                        <div className="flex items-end justify-between">
                          <h1 className="text-[#0776C5] text-2xl">0%</h1>
                          <p className="text-[#6E6E6E]">0 out of 0</p>
                        </div>
                      </div>
                    </div>
                    <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] flex flex-col pb-6 mt-4 rounded-xl">
                      <div className="">
                        <div className="flex relative w-full border border-b-black">
                          <h1 className="font-medium text-xl lg:text-2xl p-4 ">
                            Leaderboard
                          </h1>
                          <div className="absolute right-10 top-4">PTS</div>
                        </div>
                        <div className="flex items-center border py-2 px-6 justify-between border-b-black ">
                          <div className="flex items-center gap-4">
                            <img className="" src={first} alt="" />
                            <h1 className="text-[#545454]">Maestro</h1>
                          </div>
                          <p className="text-[#545454]">1500pts</p>
                        </div>
                        <div className="flex items-center border py-2 px-6 justify-between border-b-black ">
                          <div className="flex items-center gap-4">
                            <img className="" src={second} alt="" />
                            <h1 className="text-[#545454]">Ghost</h1>
                          </div>
                          <p className="text-[#545454]">1200pts</p>
                        </div>
                        <div className="flex items-center border py-2 px-6 justify-between border-b-black ">
                          <div className="flex items-center gap-4">
                            <img className="" src={third} alt="" />
                            <h1 className="text-[#545454]">Iana</h1>
                          </div>
                          <p className="text-[#545454]">1000pts</p>
                        </div>
                        <div className="flex items-center border py-2 px-7 justify-between border-b-black ">
                          <div className="flex items-center gap-6">
                            <div className="text-[#545454]">4th</div>
                            <h1 className="text-[#545454]">Twitch</h1>
                          </div>
                          <p className="text-[#545454]">900pts</p>
                        </div>
                        <div className="flex items-center  py-2 px-7 justify-between bg-[#BFDDF3]  ">
                          <div className="flex items-center gap-6">
                            <div className="text-[#545454]">8th</div>
                            <h1 className="text-[#545454]">Kapkan</h1>
                          </div>
                          <p className="text-[#545454] flex justify-center relative left-2">
                            <img className="w-[30%]" src={gLock} alt="" />
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="flex flex-col lg:w-[70%] gap-4 ">
              {/* Main Container */}
              <div>
                {locked === "one" ? (
                  <div className="w-full">
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={30}
                      pagination={{
                        clickable: true,
                      }}
                      modules={[Pagination]}
                    >
                      <SwiperSlide>
                        <div className="flex flex-col lg:flex-row gap-6 items-center justify-center lg:justify-evenly text-center p-4 mb-4 w-full">
                          {/* Video Block */}
                          <div className="flex flex-col gap-2">
                            <video
                              className="rounded-xl overflow-hidden"
                              controls
                            ></video>
                            <p>vid title</p>
                          </div>
                          <div className="flex flex-col gap-2">
                            <video
                              className="rounded-xl overflow-hidden"
                              controls
                            ></video>
                            <p>vid title</p>
                          </div>
                          <div className="flex flex-col gap-2">
                            <video
                              className="rounded-xl overflow-hidden"
                              controls
                            ></video>
                            <p>vid title</p>
                          </div>
                        </div>
                      </SwiperSlide>
                      {/* Additional SwiperSlides if needed */}
                    </Swiper>
                  </div>
                ) : locked === "three" ? (
                  <>
                    <div className="w-full">
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        pagination={{
                          clickable: true,
                        }}
                        modules={[Pagination]}
                      >
                        {/* Video Slides */}
                        {/* Repeat SwiperSlide code block here */}
                      </Swiper>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div className="lg:flex md:flex-row flex-col mx-5 gap-10 mb-10">
                  {/* Student Details */}
                  {/* <div className="md:w-[50%]  max-h-80 overflow-y-auto shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-4 rounded-xl">
                    <h1 className="font-medium text-xl lg:text-2xl text-black">
                      Student Details
                    </h1>
                    <div className="flex font-semibold ">
                      <div className="w-1/3 text-[#298BD0]">Students</div>
                      <div className="w-1/3 text-[#298BD0]">Attendance</div>
                      <div className="w-1/3 text-[#298BD0]">Homework</div>
                    </div>
                    {Object.keys(studentAttendance).length > 0 ? (
                    Object.entries(studentAttendance).map(([leadId, counts]) => (
                        <div
                          className="flex flex-col md:flex-row w-1/4 mx-2 md:mx-0 md:w-full justify-between border-b py-2 px-4 cursor-pointer"
                          key={leadId}
                        >
                          <div className="w-1/3">{counts.username}</div>
                          <div className="w-1/3">{((counts.totalPresentCount/(counts.totalPresentCount + counts.totalAbsentCount))*100).toFixed(2) }%</div>
                          <div className="w-1/3">pending</div>
                        </div>
                      ))
                    ) : (
                      <div className="text-center py-4">
                        Attendance not added yet
                      </div>
                    )}
                  </div> */}

                 
                  {/* <div className="md:w-[50%] max-h-80 overflow-y-auto shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-4 rounded-xl"> 
  <h1 className="font-medium text-xl lg:text-2xl text-black">Session Details</h1>
  <div className="flex font-semibold">
    <div className="w-1/3 text-[#298BD0]">Date</div>
    <div className="w-1/3 text-[#298BD0]">Time</div>
    <div className="w-1/3 text-[#298BD0]">Topic to be Covered</div>
  </div>
  {topics.map((topic, index) => {
     const sessionDate = sessionDates[index];
     const formattedDate = sessionDate ? new Date(sessionDate).toLocaleDateString() : 'N/A';
    return (
      <div className="flex flex-col md:flex-row w-1/4 mx-2 md:mx-0 md:w-full justify-between border-b py-2 px-4 cursor-pointer" key={topic._id}>
        <div className="w-1/3">{formattedDate}</div>
        <div className="w-1/3">{bootcampSchedules?.timeSlot || 'N/A'}</div> 
        <div className="w-1/3">{topic.TopicTitle}</div>
      </div>
    );
  })}
</div> */}



                </div>
                {/* Meeting Details */}
                {/* <div className="md:w-[46%] max-h-80 overflow-y-auto  gap-10 mx-5 shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-4 rounded-xl">
                  <h1 className="font-medium text-xl lg:text-2xl text-black">
                    Meeting Details
                  </h1>
                  <div className="flex font-semibold">
                    <div className="w-1/3 text-[#298BD0]">Date</div>
                    <div className="w-1/3 text-[#298BD0]">Start Time</div>
                    <div className="w-1/3 text-[#298BD0]">End Time</div>
                    <div className="w-1/3 text-[#298BD0]">Topic Covered</div>
                  </div>
                  {topics.map((topic) => {
                    // Find the corresponding bootcamp schedule for each topic
                    const bootcampSchedule = bootcampSchedules.find(
                      (schedule) => schedule.topicId === topic._id
                    );

                    return (
                      <div
                        className="flex flex-col md:flex-row w-1/4 mx-2 md:mx-0 md:w-full justify-between border-b py-2 px-4 cursor-pointer "
                        key={topic._id}
                      >
                        <div className="w-1/4">
                          {bootcampSchedule
                            ? bootcampSchedules.startDate
                            : "N/A"}
                        </div>
                        <div className="w-1/4">
                          {bootcampSchedules.timeSlot}
                        </div>
                        <div className="w-1/4">
                          {bootcampSchedule ? bootcampSchedules.endDate : "N/A"}
                        </div>
                        <div className="w-1/4">{topic.TopicTitle}</div>
                      </div>
                    );
                  })}
                </div> */}
                <div className="flex">

              
{selectedSession && (
  <div className="md:w-[46%] max-h-80 overflow-y-auto gap-10 mx-5 shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-4 rounded-xl">
    <div className="flex justify-between font-semibold">
      <div className="flex flex-col">
        <div className="text-sm text-[#076CB3]">
          {selectedSession.formattedDate}
        </div>
        <div>
          <h1 className="font-medium text-xl lg:text-2xl text-black">
            Session : {selectedSession.sessionNumber}
          </h1>
        </div>
      </div>
      <div className="w-1/3">
        <select
          id="topics"
          className="w-full mt-2 p-2 border rounded bg-blue-500 text-black font-semibold cursor-pointer"
          onChange={handleTopicChange}
          value={selectedTopic}
        >
          <option value="" disabled>
          Select Topics
        </option>
          {topics.map((topic) => (
            <option key={topic._id} value={topic._id}>
              {topic.TopicTitle}
            </option>
          ))}
          <option value="custom">Custom Topic</option>
        </select>
      </div>
    </div>
    {/* Show input for custom topic when "Custom Topic" is selected */}
    {selectedTopic === "custom" && (
      <div className="mt-2">
        <input
          type="text"
          placeholder="Enter custom topic"
          className="w-full p-2 border rounded"
          value={customTopic}
          onChange={handleCustomTopicChange}
        />
      </div>
    )}
    <div className="flex justify-between font-semibold mt-4">
      <div className="w-1/3 text-[#298BD0]">Students</div>
      <div className="w-1/3 text-[#298BD0]">Attendance</div>
    </div>
    {users.map((user) => (
      <div key={user.leadId} className="flex justify-between font-semibold">
        <div className="w-1/3">{user.username}</div>
        <div className="w-1/3 flex justify-center items-center">
          <input
            type="checkbox"
            id={`attendance-${user.leadId}`} // Use userId as the identifier
            checked={attendanceData[user.leadId] === "P"} // Only check based on userId
            onChange={(e) => handleAttendanceChange(e, user.leadId)} // Update based on userId
          />
        </div>
      </div>
    ))}
    <button onClick={handleSubmitAttendance} className="mt-4 flex px-8 py-1.5 bg-[#298BD0] text-white rounded-full">
      Submit Attendance
    </button>
  </div>
)}
    <div className="md:w-[50%] max-h-80 overflow-y-auto shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-4 rounded-xl"> 
      <div className="flex justify-between">
<div>
  <h1 className="font-medium text-xl lg:text-2xl text-black">Leave Request</h1>

</div>
<button className="mt-4 flex px-8 py-1.5 bg-[#298BD0] text-white rounded-full" onClick={() => setShowModal(true)}>

Request Leave
</button>
      </div>
  <div className="flex font-semibold">
    <div className="w-1/3 text-[#298BD0]">Date</div>
    <div className="w-1/3 text-[#298BD0]">Reason</div>
    <div className="w-1/3 text-[#298BD0]">Status</div>
  </div>
  { allLeaveData ? (allLeaveData.map((leaves, index) => {
     
    return (
      <div className="flex flex-col md:flex-row w-1/4 mx-2 md:mx-0 md:w-full justify-between border-b py-2 px-4 cursor-pointer" key={leaves._id}>
        <div className="w-1/3">
          {new Date(
                                    leaves.date
                                    ).toLocaleDateString()}
        </div>
        <div className="w-1/3">{leaves.
reason
}</div> {/* Use optional chaining */}
        <div className="w-1/3">{leaves.status}</div>
      </div>
    );
  })):(
    <div>
       no leave request
      </div>
  )}
</div>
</div>


              </div>

              {locked === "one" ? (
                <>
                  <div className="flex flex-col lg:flex-row h-full gap-6">
                    {batchStatus?.payStatus === "Completed" ? (
                      <div className="lg:w-[35%] shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-4 rounded-xl ">
                        <h1 className="font-medium text-xl lg:text-2xl text-black  ">
                          Payment
                        </h1>

                        <div className="flex items-center justify-center relative mx-16 lg:mx-10">
                          <div className="absolute items-center flex flex-col justify-center text-[#114A82]">
                            <div className="justify-center">
                              Payment Completed
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="lg:w-[35%] shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-4 rounded-xl ">
                        <h1 className="font-medium text-xl lg:text-2xl text-black  ">
                          Payment
                        </h1>

                        <div className="flex items-center justify-center relative mx-16 lg:mx-10">
                          <div className="absolute items-center flex flex-col justify-center text-[#114A82]">
                            <h1 className="text-[#59AAD8] font-semibold">
                              Days
                            </h1>
                            <h2 className="text-[#665B5B] font-semibold  ">
                              100
                            </h2>
                            <h3 className="text-[#59AAD8] text-8xl font-bold  ">
                              71
                            </h3>
                            <h1 className="text-[#59AAD8] font-semibold">
                              Remaining
                            </h1>
                          </div>
                          <CircularProgressbar
                            value={71}
                            styles={buildStyles({
                              rotation: -0.5,
                            })}
                            className="py-2"
                          />
                        </div>
                        <div className="flex justify-center ">
                          <button className="px-4 py-1.5 my-2 bg-black text-white rounded ">
                            Renew Now
                          </button>
                        </div>
                        <div className="flex flex-col  mt-16 ">
                          <div className="flex justify-center items-center gap-2">
                            <hr className="w-[20%]" />
                            <div className="text-[#ACACAC]">Pay using</div>
                            <hr className="w-[20%]" />
                          </div>
                          <div className="flex justify-center">
                            <img
                              className="cursor-pointer"
                              src={master}
                              alt=""
                            />
                            <img className="cursor-pointer" src={visa} alt="" />
                            <img className="cursor-pointer" src={pay} alt="" />
                            <img className="cursor-pointer" src={bhim} alt="" />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="lg:w-[65%] flex flex-col gap-6 ">
                      <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-4 rounded-xl">
                        <h1 className="font-medium text-xl text-black  ">
                          Pending Homework
                        </h1>
                        <div className="grid grid-cols-2 lg:grid-cols-3 gap-6 m-6">
                          <div className="flex flex-col cursor-pointer text-[#565656] text-center shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-xl pt-10 pb-4 relative">
                            <p className="flex absolute right-0 top-3 bg-[#BCE0FA] text-white px-3 py-0.5 gap-1 rounded-l">
                              <img className="w-[80%]" src={clk} alt="" />
                              <div className="text-[#298BD0] text-sm">
                                Pending
                              </div>
                            </p>
                            <h1 className="font-semibold">Rook</h1>
                            <h2 className="font-semibold">12 May 2024</h2>
                          </div>
                          <div className="flex flex-col cursor-pointer text-[#565656] text-center shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-xl pt-10 pb-4 relative">
                            <p className="flex absolute right-0 top-3 bg-[#BCE0FA] text-white px-3 py-0.5 gap-1 rounded-l">
                              <img className="w-[80%]" src={clk} alt="" />
                              <div className="text-[#298BD0] text-sm">
                                Pending
                              </div>
                            </p>
                            <h1 className="font-semibold">Rook</h1>
                            <h2 className="font-semibold">12 May 2024</h2>
                          </div>
                          <div className="flex flex-col cursor-pointer text-[#565656] text-center shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-xl pt-10 pb-4 relative">
                            <p className="flex absolute right-0 top-3 bg-[#BCE0FA] text-white px-3 py-0.5 gap-1 rounded-l">
                              <img className="w-[80%]" src={clk} alt="" />
                              <div className="text-[#298BD0] text-sm">
                                Pending
                              </div>
                            </p>
                            <h1 className="font-semibold">Rook</h1>
                            <h2 className="font-semibold">12 May 2024</h2>
                          </div>
                          <div className="flex flex-col cursor-pointer text-[#565656] text-center shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-xl pt-10 pb-4 relative">
                            <p className="flex absolute right-0 top-3 bg-[#BCE0FA] text-white px-3 py-0.5 gap-1 rounded-l">
                              <img className="w-[80%]" src={clk} alt="" />
                              <div className="text-[#298BD0] text-sm">
                                Pending
                              </div>
                            </p>
                            <h1 className="font-semibold">Rook</h1>
                            <h2 className="font-semibold">12 May 2024</h2>
                          </div>
                          <div className="flex flex-col cursor-pointer text-[#565656] text-center shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-xl pt-10 pb-4 relative">
                            <p className="flex absolute right-0 top-3 bg-[#FBCCB7] text-white px-3 py-0.5 gap-1 rounded-l">
                              <div className="text-[#FF6723] text-sm">
                                Upcoming
                              </div>
                            </p>
                            <h1 className="font-semibold">Rook</h1>
                            <h2 className="font-semibold">12 May 2024</h2>
                          </div>
                          <div className="flex flex-col cursor-pointer text-[#565656] text-center shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-xl pt-10 pb-4 relative">
                            <p className="flex absolute right-0 top-3 bg-[#FBCCB7] text-white px-3 py-0.5 gap-1 rounded-l">
                              <div className="text-[#FF6723] text-sm">
                                Upcoming
                              </div>
                            </p>
                            <h1 className="font-semibold">Rook</h1>
                            <h2 className="font-semibold">12 May 2024</h2>
                          </div>
                        </div>
                      </div>
                      <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-4 rounded-xl ">
                        <h1 className="font-medium text-xl text-black pb-6 ">
                          Ask a coach
                        </h1>
                        <div className="flex w-full justify-center items-center">
                          <input
                            className="px-4 py-2.5 border border-gray-500 rounded-full w-[80%]"
                            placeholder="Write a message.."
                            type="text"
                          />
                          <button>
                            <img src={sen} alt="" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : locked === "three" ? (
                <>
                  <div className="flex flex-col lg:flex-row h-full gap-6">
                    <div className="lg:w-[35%] shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-4 rounded-xl ">
                      <h1 className="font-medium text-xl lg:text-2xl text-black  ">
                        Payment
                      </h1>
                      <div className="flex items-center justify-center relative mx-16 lg:mx-10">
                        <div className="absolute items-center flex flex-col justify-center text-[#114A82]">
                          <h1 className="text-[#59AAD8] font-semibold">Days</h1>
                          {batchDetail ? (
                            <h2 className="text-[#665B5B] font-semibold  ">
                              {daysRemaining}
                            </h2>
                          ) : (
                            <h2 className="text-[#665B5B] font-semibold  ">
                              0
                            </h2>
                          )}
                          <h3 className="text-[#59AAD8] text-8xl font-bold  ">
                            <img src={gLock} alt="" />
                          </h3>
                          <h1 className="text-[#59AAD8] font-semibold">
                            Remaining
                          </h1>
                        </div>
                        <CircularProgressbar
                          value={71}
                          styles={buildStyles({
                            rotation: -0.5,
                          })}
                          className="py-2"
                        />
                      </div>
                      <div className="flex justify-center ">
                        <button
                          className="px-4 py-1.5 my-2 bg-black text-white rounded "
                          onClick={() => navigate("/orders/batch")}
                        >
                          Pay Now
                        </button>
                      </div>
                      <div className="flex flex-col  mt-16 ">
                        <div className="flex justify-center items-center gap-2">
                          <hr className="w-[20%]" />
                          <div className="text-[#ACACAC]">Pay using</div>
                          <hr className="w-[20%]" />
                        </div>
                        <div className="flex justify-center">
                          <img className="cursor-pointer" src={master} alt="" />
                          <img className="cursor-pointer" src={visa} alt="" />
                          <img className="cursor-pointer" src={pay} alt="" />
                          <img className="cursor-pointer" src={bhim} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="lg:w-[65%] flex flex-col gap-6 ">
                      <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-4 rounded-xl grayscale cursor-not-allowed">
                        <h1 className="font-medium text-xl text-black flex ">
                          Pending Homework
                          <img className="w-[4%] ml-4" src={gLock} alt="" />
                        </h1>
                        <div className="grid grid-cols-2 lg:grid-cols-3 gap-6 m-6">
                          <div className="flex flex-col cursor-pointe text-[#565656] text-center shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-xl pt-10 pb-4 relative">
                            <p className="flex absolute right-0 top-3 bg-[#BCE0FA] text-white px-3 py-0.5 gap-1 rounded-l">
                              <img className="w-[80%]" src={clk} alt="" />
                              <div className="text-[#298BD0] text-sm">
                                Pending
                              </div>
                            </p>
                            <h1 className="font-semibold">Rook</h1>
                            <h2 className="font-semibold">12 May 2024</h2>
                          </div>
                          <div className="flex flex-col cursor-pointe text-[#565656] text-center shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-xl pt-10 pb-4 relative">
                            <p className="flex absolute right-0 top-3 bg-[#BCE0FA] text-white px-3 py-0.5 gap-1 rounded-l">
                              <img className="w-[80%]" src={clk} alt="" />
                              <div className="text-[#298BD0] text-sm">
                                Pending
                              </div>
                            </p>
                            <h1 className="font-semibold">Rook</h1>
                            <h2 className="font-semibold">12 May 2024</h2>
                          </div>
                          <div className="flex flex-col cursor-pointe text-[#565656] text-center shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-xl pt-10 pb-4 relative">
                            <p className="flex absolute right-0 top-3 bg-[#BCE0FA] text-white px-3 py-0.5 gap-1 rounded-l">
                              <img className="w-[80%]" src={clk} alt="" />
                              <div className="text-[#298BD0] text-sm">
                                Pending
                              </div>
                            </p>
                            <h1 className="font-semibold">Rook</h1>
                            <h2 className="font-semibold">12 May 2024</h2>
                          </div>
                          <div className="flex flex-col cursor-pointe text-[#565656] text-center shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-xl pt-10 pb-4 relative">
                            <p className="flex absolute right-0 top-3 bg-[#BCE0FA] text-white px-3 py-0.5 gap-1 rounded-l">
                              <img className="w-[80%]" src={clk} alt="" />
                              <div className="text-[#298BD0] text-sm">
                                Pending
                              </div>
                            </p>
                            <h1 className="font-semibold">Rook</h1>
                            <h2 className="font-semibold">12 May 2024</h2>
                          </div>
                          <div className="flex flex-col cursor-pointe text-[#565656] text-center shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-xl pt-10 pb-4 relative">
                            <p className="flex absolute right-0 top-3 bg-[#FBCCB7] text-white px-3 py-0.5 gap-1 rounded-l">
                              <div className="text-[#FF6723] text-sm">
                                Upcoming
                              </div>
                            </p>
                            <h1 className="font-semibold">Rook</h1>
                            <h2 className="font-semibold">12 May 2024</h2>
                          </div>
                          <div className="flex flex-col cursor-pointe text-[#565656] text-center shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-xl pt-10 pb-4 relative">
                            <p className="flex absolute right-0 top-3 bg-[#FBCCB7] text-white px-3 py-0.5 gap-1 rounded-l">
                              <div className="text-[#FF6723] text-sm">
                                Upcoming
                              </div>
                            </p>
                            <h1 className="font-semibold">Rook</h1>
                            <h2 className="font-semibold">12 May 2024</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </section>
      <LeaveRequestModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        leaveData={leaveData}
        handleChange={handleChange}
        handleSubmit={handleSubmitLeave}
      />
    </>
  );
};

export default CoachDemoDashboard;
