/** @format */

import { toast } from "react-toastify";

export const successRegistrationToast = () =>
  toast.success(`Registered successfully`);
export const failedRegistrationToast = () => toast.error(`Registration failed`);
export const successLoginToast = () => toast.success(`Logged in successfully`);
export const failedLoginToast = () =>
  toast.error(`Login failed, check credentials and try again`);
export const successLogoutToast = () =>
  toast.success(`Logged out successfully’`);
export const failedLogoutToast = () => toast.error(`logout failed`);
export const warningRegistrationToast = () =>
  toast.error("Invalid! Check credentials and try again.");
export const warningPasswordShouldMatchToast = () =>
  toast.warning("The password and confirm password  should match!");
export const warningEnterAllDetailsToast = () =>
  toast.warning("please enter the field details!");
export const warningCustomToast = (txt) => toast.warning(txt);
export const successCustomToast = (txt) => toast.success(txt);
export const failedCustomToast = (txt) => toast.error(txt);
export const infoCustomToast = (txt) => toast.info(txt);
export const successForgotToast = () => toast.success(`Please check Email`);
export const courseCoachToast = () =>
  toast.error(`You can't buy your own course`);
export const failedForgotToast = () =>
  toast.error(`please check email and try again`);
export const successRestToast = () =>
  toast.success(`password change successfully`);
export const failedRestToast = () => toast.error(`network issue`);
export const contactUsSuccessToast = () =>
  toast.success(`Your message was successfully sent`);
export const contactUsErrorToast = () => toast.success(`Error sending message`);
export const saveEmailBootcamp = () =>
  toast.success(`Email Saved successfully`);
export const savePhoneNumberBootcamp = () =>
  toast.success(`Phone Number Saved successfully`);
export const informationNotFilledBootcamp = () =>
  toast.error(`You must enter the information!`);
export const timeslotFilledCoach = () =>
  toast.error(`You must select Time slot`);
export const copyMoveText = () =>
  toast.success(`Move Text Copied to Clipboard`);
export const copyFenString = () =>
  toast.success(`Fen String Copied to Clipboard`);
export const registerForFreeClass = () =>
  toast.success(`You Registered for free Class successfully`);
export const leadAddedSuccess = () => toast.success(`Lead Added Successfully`);
export const batchAddedSuccess = () =>
  toast.success(`Batch Added Successfully`);
export const demoAddedSuccess = () =>
  toast.success(`Demo Class Added Successfully`);
export const demoClassSuccess = () =>
  toast.success(`Demo Class Schedule Successfully`);
export const batchClassSuccess = () =>
  toast.success(`Batch Schedule Successfully`);
export const batchCenterSuccess = () =>
  toast.success(`Batch Center Created Successfully`);
export const attendanceSuccess = () =>
  toast.success(`Attendance Added Successfully`);
export const filledDetails = () =>
  toast.warn(`Please fill in all required fields.`);
export const selectCategory = () => toast.warn(`Please select catgory`);
export const registerationSuccessfull = () =>
  toast.success(`You have successfull register for tournament`);
export const phoneNumberExist = () =>
  toast.error(`Phone Number already existed`);
export const errorUpdatingStatus = () =>
  toast.error(`Error updating leave status`);
export const errorUpdatingAttendance = () =>
  toast.error(`Error updating Attendance`);
export const leaveSubmitted = () =>
  toast.success(`Leave request submitted successfully`);
export const errorleaveSubmitted = () =>
  toast.success(`Failed to submit leave request`);
