import React from "react";

const LeaveRequestModal = ({ isOpen, onClose, leaveData, handleChange, handleSubmit }) => {
  if (!isOpen) return null;
  const reasons = ["Not Well", "Personal leave", "Holiday"];

  const handleReasonSelect = (reason) => {
    handleChange({ target: { name: "reason", value: reason } });
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50 text-black font-poppins">
      <div className="bg-white rounded-lg w-full max-w-md relative">
        {/* Header */}
        <div className="bg-gray-800 text-white p-4 rounded-t-lg flex justify-between items-center">
          <h2 className="text-xl font-semibold">Leave Request</h2>
          <button
            onClick={onClose}
            className="text-white hover:text-gray-300 transition-colors"
          >
            <div className="h-5 w-5">X</div>
          </button>
        </div>

        {/* Form */}
        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          {/* Date */}
          <div className="space-y-2">
            <label className="block text-sm font-medium">Date :-</label>
            <input
              type="date"
              name="date"
              value={leaveData.date}
              onChange={handleChange}
              className="w-full border rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Reason */}
          <div className="space-y-2">
            <label className="block text-sm font-medium">Reason :-</label>
            <div className="flex flex-wrap gap-2">
              {reasons.map((reason) => (
                <button
                  key={reason}
                  type="button"
                  onClick={() => handleReasonSelect(reason)} // Update reason on click
                  className={`px-4 py-2 rounded-md border transition-colors ${
                    leaveData.reason === reason
                      ? "bg-[#298BD0] text-white border-blue-500"
                      : "bg-white text-gray-700 border-gray-300 hover:bg-gray-50"
                  }`}
                >
                  {reason}
                </button>
              ))}
            </div>
          </div>

          {/* Description */}
          <div className="space-y-2">
            <label className="block text-sm font-medium">Describe :-</label>
            <textarea
              name="description"
              value={leaveData.description}
              onChange={handleChange}
              rows={4}
              className="w-full border rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Submit Button */}
          <div className="pt-4">
            <button
              type="submit"
              className="w-full bg-[#298BD0] text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LeaveRequestModal;
