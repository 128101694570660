/** @format */

import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import cross from "../../../assets/admin/cross.svg";

const backdrop = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const modal = {
  hidden: {
    y: "150px",
    opacity: 0,
  },
  visible: {
    y: "150px",
    opacity: 1,
  },
};

const BatchScheduleModal = ({ showModal, setShowModal, setScheduleData }) => {
  const [day, setDay] = useState([]);
  const [inTym, setInTym] = useState();
  const [outTym, setOutTym] = useState();

  // stop propagation handler
  const stopPropagationHandler = (e) => {
    e.stopPropagation();
  };

  const toggleDay = (dayToToggle) => {
    setDay((prevDays) => {
      if (prevDays.includes(dayToToggle)) {
        return prevDays.filter((day) => day !== dayToToggle);
      } else {
        return [...prevDays, dayToToggle];
      }
    });
  };

  const isDaySelected = (dayName) => day.includes(dayName);

  const handleSchedule = () => {
    setScheduleData({
      days: day,
      timeFrom: inTym,
      timeTo: outTym,
    });
    setShowModal(false);
  };

  // connect to the chess server
  return (
    <>
     <AnimatePresence>
        {showModal && (
          <motion.div
            variants={backdrop}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className="bg-[rgba(0,0,0,0.5)] h-screen w-full fixed top-0 left-0 z-30"
            onClick={() => setShowModal(false)}
          >
            <motion.div
              onClick={stopPropagationHandler}
              variants={modal}
              initial="hidden"
              animate="visible"
              className="bg-white max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg mx-auto max-h-screen flex-col justify-center rounded-2xl overflow-hidden z-30 mt-5 bg-cover bg-center shadow-[0_8px_30px_rgb(0,0,0,0.12)]"
            >
              <div className="w-full flex justify-center px-6 text-center bg-[#3F4447] text-white relative py-8">
                <h1 className="text-2xl font-bold">Select Date and Time</h1>
                <img
                  className="absolute right-8 cursor-pointer"
                  onClick={() => setShowModal(false)}
                  src={cross}
                  alt=""
                />
              </div>
              <div className="bg-white py-4">
                <div className="flex flex-col gap-2">
                  <div className="font-semibold text-lg text-center">
                    Select Weekday
                  </div>
                  <div className="grid grid-cols-3 p-6 items-center justify-center">
                    <div
                      onClick={() => toggleDay("monday")}
                      className={`select-none border text-center m-2 rounded py-2 cursor-pointer ${
                        isDaySelected("monday") ? "bg-[#298BD0] text-white border-black" : ""
                      }`}
                    >
                      Monday
                    </div>
                    <div
                      onClick={() => toggleDay("tuesday")}
                      className={`select-none border text-center m-2 rounded py-2 cursor-pointer ${
                        isDaySelected("tuesday") ? "bg-[#298BD0] text-white border-black" : ""
                      }`}
                    >
                      tuesday
                    </div>
                    <div
                      onClick={() => toggleDay("wednesday")}
                      className={`select-none border text-center m-2 rounded py-2 cursor-pointer ${
                        isDaySelected("wednesday") ? "bg-[#298BD0] text-white border-black" : ""
                      }`}
                    >
                     Wednesday
                    </div>
                    <div
                      onClick={() => toggleDay("thursday")}
                      className={`select-none border text-center m-2 rounded py-2 cursor-pointer ${
                        isDaySelected("thursday") ? "bg-[#298BD0] text-white border-black" : ""
                      }`}
                    >
                      Thursday
                    </div>
                    <div
                      onClick={() => toggleDay("friday")}
                      className={`select-none border text-center m-2 rounded py-2 cursor-pointer ${
                        isDaySelected("friday") ? "bg-[#298BD0] text-white border-black" : ""
                      }`}
                    >
                     Friday
                    </div>
                    <div
                      onClick={() => toggleDay("saturday")}
                      className={`select-none border text-center m-2 rounded py-2 cursor-pointer ${
                        isDaySelected("saturday") ? "bg-[#298BD0] text-white border-black" : ""
                      }`}
                    >
                      Saturday
                    </div>
                    <div
                      onClick={() => toggleDay("sunday")}
                      className={`select-none border text-center m-2 rounded py-2 cursor-pointer ${
                        isDaySelected("sunday") ? "bg-[#298BD0] text-white border-black" : ""
                      }`}
                    >
                     Sunday
                    </div>
                   
                  </div>
                </div>

                <div className="flex flex-col gap-2 justify-center items-center">
                  <div className="font-semibold text-lg text-center">
                    Select Time Slot
                  </div>
                  <div className="flex items-center gap-4">
                    <h1>From</h1>
                    <input
                      className="border rounded px-4 py-1"
                      value={inTym}
                      onChange={(e) => setInTym(e.target.value)}
                      type="time"
                    />
                    <h1>To</h1>
                    <input
                      value={outTym}
                      onChange={(e) => setOutTym(e.target.value)}
                      className="border rounded px-4 py-1"
                      type="time"
                    />
                  </div>
                </div>
                <div className="flex w-full px-6 mt-6 justify-end">
                  <button
                    onClick={handleSchedule}
                    className="bg-[#3796E9] text-white rounded-lg px-6 py-2 select-none cursor-pointer flex"
                  >
                    Schedule
                  </button>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default BatchScheduleModal;
