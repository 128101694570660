import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import axios from 'axios';
import cross from "../../../assets/admin/cross.svg";
import { demoClassSuccess } from "../../../utils/notifications";

const backdrop = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const modal = {
  hidden: {
    y: "150px",
    opacity: 0,
  },
  visible: {
    y: "150px",
    opacity: 1,
  },
};

const LeadsScheduleModal = ({ showModal, setShowModal, lead }) => {
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [schedules, setSchedules] = useState([]);

  const stopPropagationHandler = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    const fetchSchedules = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/getDemoClassSchedule`);
  
        // Filter out schedules where the start date is before the current date
        const filteredSchedules = response.data.filter(schedule => {
          const scheduleDate = new Date(schedule.startDate);
          const currentDate = new Date();
  
          // Ignore time and compare only the date parts
          const isSameOrAfterCurrentDate =
            scheduleDate.setHours(0, 0, 0, 0) >= currentDate.setHours(0, 0, 0, 0);
  
          return isSameOrAfterCurrentDate;
        });
  
        setSchedules(filteredSchedules);
      } catch (err) {
        console.error(err.message);
      }
    };
  
    fetchSchedules();
  }, []);
  
  const handleScheduleChange = (index) => {
    setSelectedSchedule(index);
  };

  const handleScheduleClick = async () => {
    if (selectedSchedule === null) return;

    const scheduleData = {
      name: lead.name,
      phoneNumber: lead.phoneNumber,
      email: lead.email,
      demoId: schedules[selectedSchedule]._id,
      leadId: lead._id
    };

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/registerDemoClass`, scheduleData);

      setShowModal(false);
      window.location.reload();
      demoClassSuccess();

    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <motion.div
            variants={backdrop}
            initial="hidden"
            animate="visible"
            exit={{ opacity: 0 }}
            className="bg-[rgba(0,0,0,0.5)] h-screen w-full fixed top-0 left-0 z-30"
            onClick={() => setShowModal(false)}
          >
            <motion.div
              onClick={stopPropagationHandler}
              variants={modal}
              initial="hidden"
              animate="visible"
              style={{ display: "flex" }}
              className="bg-white max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg mx-auto max-h-screen flex-col justify-center rounded-2xl overflow-hidden z-30 mt-5 bg-cover bg-center shadow-[0_8px_30px_rgb(0,0,0,0.12)]"
            >
              <div className="w-full flex justify-center px-6 text-center bg-[#3F4447] text-white relative py-8">
                <h1 className="text-2xl font-bold">Select Date and Time</h1>
                <img
                  className="absolute right-8 cursor-pointer"
                  onClick={() => setShowModal(false)}
                  src={cross}
                  alt="close"
                />
              </div>
              <div className="bg-white py-4">
                <div className="flex px-4 text-center font-semibold mb-4">
                  <div className="w-1/4"> </div>
                  <div className="w-1/4">Date</div>
                  <div className="w-1/4">Time</div>
                  <div className="w-1/4">Slots</div>
                </div>
                {schedules.map((schedule, index) => (
                  <div className="flex flex-col gap-2" key={schedule._id}>
                    <div className="flex px-4 text-center cursor-pointer">
                      <div className="w-1/4">
                        <input
                          type="radio"
                          checked={selectedSchedule === index}
                          onChange={() => handleScheduleChange(index)}
                        />
                      </div>
                      <div onClick={() => handleScheduleChange(index)} className="w-1/4">
                        {new Date(schedule.startDate).toLocaleDateString()}
                      </div>
                      <div onClick={() => handleScheduleChange(index)} className="w-1/4">
                        {schedule.timeSlot}
                      </div>
                      <div onClick={() => handleScheduleChange(index)} className="w-1/4">
                        {schedule.availableSeat}
                      </div>
                    </div>
                  </div>
                ))}
                <div className="flex w-full px-6 mt-6 justify-end">
                  <button
                    onClick={handleScheduleClick}
                    className="bg-[#3796E9] text-white rounded-lg px-6 py-2 select-none cursor-pointer flex"
                  >
                    Schedule
                  </button>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default LeadsScheduleModal;
