import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import sen from "../../../assets/admin/sen.svg";
import cross from "../../../assets/admin/cross.svg";
import { Bounce, toast } from "react-toastify";
import LeadsScheduleModal from "./LeadsScheduleModal";
import BatchScheduleModal from "./BatchScheduleModal";
import Batch from "./Batch";
import { batchAddedSuccess, contactUsSuccessToast } from "../../../utils/notifications";
import { FaArrowDown } from "react-icons/fa6";
const AdminBatchCenter = ({ theBatch, setTheBatch }) => {
  const [filter, setFilter] = useState("all");
  const [showTab, setShowTab] = useState(false);
  const [coach, setCoach] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showBatch, setShowBatch] = useState(false);
  const auth = JSON.parse(localStorage.getItem("auth"));
  const [scheduleData, setScheduleData] = useState({});
  const [batchdata, setBatchData] = useState([]);
  const [selectedBatchId, setSelectedBatchId] = useState(null);
  const [selectedBatchType, setSelectedBatchType] = useState('All');
  const [formData, setFormData] = useState({
    name: "",
    class_latitude: "",
    class_longtitude: "",
    actualLocation: "",
    googleMapLink: ""
  });

  const modal = {
    hidden: {
      x: 900,
      opacity: 0,
      transition: { type: "smooth", stiffness: 1000 },
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: { type: "smooth", stiffness: 1000 },
    },
  };

  const headers = [
    "Date Added",
    "Name",
    "Coach",
    "Time",
    "Booked Slots",
    "Available Slots",
    "Confirmed Slots",
    "Total Slots",
    "Mode",
    "Send Msg",
  ];




  const handleChange = (e) => {
    
      setFormData({ ...formData, [e.target.name]: e.target.value });
   
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataToSubmit = {
        ...formData,
      };

      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/create-Batch-Center`,
        dataToSubmit,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth?.accessToken}`,
          },
        }
      );

      batchAddedSuccess();

      setFormData({
        name: "",
        class_latitude: "",
        class_longtitude: "",
        actualLocation: "",
        googleMapLink: ""
      });
      setScheduleData({}); // Reset schedule data
      window.location.reload();
    } catch (error) {
      console.error("Error saving data:", error);
      alert("Error saving data. Please try again.");
    }
  };

  useEffect(() => {
    const fetchSchedules = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/all-Batch-Center`,
          {
            headers: {
              authorization: `Bearer ${auth?.accessToken}`,
            },
          }
        );
        setBatchData(response.data);
      } catch (err) {
        console.error(err.message);
      }
    };

    fetchSchedules();
  }, []);

  return (
    <>
      <Batch
        showBatch={showBatch}
        setShowBatch={setShowBatch}
        batchId={selectedBatchId}
      />
      <BatchScheduleModal
        showModal={showModal}
        setShowModal={setShowModal}
        setScheduleData={setScheduleData} // Pass the setter function
      />
  <div className="p-2 w-full px-2 font-poppins min-h-full flex flex-col lg:flex-row">
        <div className={`flex flex-col w-full lg:w-${showTab ? '3/5' : 'full'} transition-all duration-300`}>
          <h1 className="text-2xl md:text-3xl font-bold my-4">Center List</h1>
          <div className="flex flex-col shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-4 md:p-6 my-6 rounded-lg transition-all">
            <div className="flex flex-col sm:flex-row justify-between w-full mb-4">
              <div className="flex items-center gap-1 select-none mb-2 sm:mb-0">
                <div
                  onClick={() => setFilter("all")}
                  className={`rounded px-4 py-1 cursor-pointer ${
                    filter === "all" ? "bg-[#D9D9D9] text-[#3796E9]" : ""
                  }`}
                >
                  All
                </div>
                <div
                  onClick={() => setFilter("un")}
                  className={`rounded px-4 py-1 cursor-pointer ${
                    filter === "un" ? "bg-[#D9D9D9] text-[#3796E9]" : ""
                  }`}
                >
                  Unread
                </div>
              </div>
              <div
                onClick={() => setShowTab(!showTab)}
                className="bg-[#3796E9] text-white rounded-lg px-6 py-2 select-none cursor-pointer text-center"
              >
                + Add Batch
              </div>
            </div>
            <div className="overflow-x-auto w-full">
              <div className="flex justify-center w-full">
              <table className="w-[80%] text-sm lg:block hidden ">
                <thead>
                  <tr className="text-[#114A82] border-b border-[#114a8250]">
                    <th className="py-2 px-4 text-left">Name</th>
                    <th className="py-2 px-4 text-left">Latitude</th>
                    <th className="py-2 px-4 text-left">Longtitude</th>
                    <th className="py-2 px-4 text-left">Address</th>
                    <th className="py-2 px-4 text-left">Google Map Link</th>
                
                  </tr>
                </thead>
                <tbody>
                  {batchdata.map((batch, index) => (
                    <tr
                      key={index}
                      className="border-b cursor-pointer hover:bg-gray-50"
                      
                    >
                      <td className="py-2 px-4 hover:underline">{batch.name}</td>
                      <td className="py-2 px-4">{batch.class_latitude}</td>
                      <td className="py-2 px-4">{batch.class_longtitude}</td>
                      <td className="py-2 px-4">{batch.actualLocation}</td>
                      <td className="py-2 px-4">{batch.googleMapLink}</td>
                      
                    
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
              <div className="lg:hidden">

        {batchdata

          .map((batch, index) => (
            <div 
              key={index}
              className="border-b border-gray-200 p-4 space-y-2"

            >
           
              <div className="grid grid-cols-2 gap-2 text-sm">
                <div><span className="font-semibold">Location Name:</span> {batch.name}</div>
                <div><span className="font-semibold">Address:</span> {batch.actualLocation}</div>
                <div><span className="font-semibold">Latitude:</span> {batch.class_latitude}</div>
                <div><span className="font-semibold">Longitude:</span> {batch.class_longtitude}</div>
                <div><span className="font-semibold">Google Map Link:</span> {batch.googleMapLink}</div>
               
              </div>
            
            </div>
          ))}
      </div>

            </div>
          </div>
        </div>


        <motion.div
          variants={modal}
          inherit="hidden"
          animate={showTab ? "visible" : "hidden"}
          className={`w-[40%] shadow-[0_3px_10px_rgb(0,0,0,0.2)]  ml-4 pb-4 rounded-lg ${
            showTab ? "" : "absolute right-0"
          } `}
        >
          <div className="flex flex-col    ">
            <div className="flex flex-col gap-2  ">
              <div className="flex justify-between items-center pt-6 px-6  ">
                <h1 className="text-[#3796E9] text-lg">Add New Batch Center</h1>
                <div
                  className="text-xl cursor-pointer"
                  onClick={() => setShowTab(!showTab)}
                >
                  <img src={cross} alt="" />
                </div>
              </div>
              <div className="flex flex-col text-sm mb-2 px-6 shadow   pb-4 "></div>
              <div className="flex flex-col px-6 gap-4 shadow py-4 ">
                <div>
                  <h1 className="flex font-bold text-xl ">Name of Center</h1>
                  <div className="mt-2 relative">
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="w-full h-12 border px-6 pr-16 rounded  "
                      placeholder="Write a name....."
                    ></input>
                    <img
                      className="absolute top-1.5 right-1 cursor-pointer"
                      src={sen}
                      alt=""
                    />
                  </div>
                </div>
                <div>
                  <h1 className="flex font-bold text-xl ">Class Latitude</h1>
                  <div className="mt-2 relative">
                    <input
                      type="text"
                      name="class_latitude"
                      value={formData.class_latitude}
                      onChange={handleChange}
                      className="w-full h-12 border px-6 pr-16 rounded  "
                    ></input>
                    <img
                      className="absolute top-1.5 right-1 cursor-pointer"
                      src={sen}
                      alt=""
                    />
                  </div>
                </div>
                <div>
                  <h1 className="flex font-bold text-xl ">Class longtitude</h1>
                  <div className="mt-2 relative">
                    <input
                      type="text"
                      name="class_longtitude"
                      value={formData.class_longtitude}
                      onChange={handleChange}
                      className="w-full h-12 border px-6 pr-16 rounded  "
                    ></input>
                    <img
                      className="absolute top-1.5 right-1 cursor-pointer"
                      src={sen}
                      alt=""
                    />
                  </div>
                </div>
              
                <div>
                  <h1 className="flex font-bold text-xl ">Address</h1>
                  <div className="flex justify-start mx-4 my-3 w-full">
                    <input
                      className="border px-4 py-1 rounded"
                      // placeholder="select"
                      type="text"
                      name="actualLocation"
                      value={formData.actualLocation}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div>
                  <h1 className="flex font-bold text-xl ">Google Map Link</h1>
                  <div className="flex justify-start mx-4 my-3 w-full">
                    <input
                      className="border px-4 py-1 rounded"
                      // placeholder="select"
                      type="text"
                      name="googleMapLink"
                      value={formData.googleMapLink}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              
              </div>

              <div className="px-6 flex flex-col gap-4">

                <div className="flex flex-col gap-6 w-full ">
                  <div className="flex w-1/2 gap-4 flex-col">
                  
                  </div>
                  <div className="flex justify-end">
                    <button
                      onClick={handleSubmit}
                      className="px-12 py-2 flex bg-[#3796E9] text-white rounded-xl  justify-center    "
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default AdminBatchCenter;
