import axios from "axios";
import React, { useEffect, useState } from "react";

import Calendar from "react-calendar"; // Import react-calendar
import "react-calendar/dist/Calendar.css";
export default function UserPaymentHistory({ user, isOpen, onClose }) {

  
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex justify-end z-50 overflow-y-scroll">
      <div className="bg-white rounded-lg w-[400px] max-h-[90vh] overflow-auto">
        {/* User Info Header */}
        <div className="p-4 border-b">
          <div className="flex justify-between items-center mb-2">
            <div className="text-sm text-gray-600">
              Username: {user.username}
            </div>
          
            <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
              ×
            </button>
          </div>
          <div className="text-sm text-gray-600">
              User Id: {user.userId}
            </div>
            <div className="text-sm text-gray-600">
            lead Id: {user.leadId}
            </div>
          <div className="text-sm text-gray-600">
            Joined date: {new Date(user.createdAt).toLocaleDateString()}
          </div>
        </div>

        {/* Payment History Title */}
        <div className="px-4 py-2 bg-gray-50">
          <div className="font-medium text-orange-500">
            Payment history
          </div>
        </div>

        {/* Timeline */}
        <div className="p-4">
          <div className="relative">
            {user.paymentHistory.map((payment, index) => (
              <div key={index} className="flex gap-3 mb-6 relative">
                {/* Timeline line */}
                {index !== user.paymentHistory.length - 1 && (
                  <div className="absolute left-[11px] top-[24px] w-[2px] h-[calc(100%-12px)] bg-gray-200" />
                )}
                
                {/* Timeline dot */}
                <div className="relative">
                  <div className={`w-6 h-6 rounded-full border-2 flex items-center justify-center
                    ${payment.payStatus === 'Completed' ? 'border-[#90EE90] bg-white' : 'border-orange-500 bg-white'}`}>
                    <div className={`w-2 h-2 rounded-full
                      ${payment.payStatus === 'Completed' ? 'bg-[#90EE90]' : 'bg-orange-500'}`} />
                  </div>
                </div>

                {/* Content */}
                <div className="flex-1">
                  <div className="text-sm text-gray-600 mb-1">
                    {new Date(payment.startDate).toLocaleDateString()}
                  </div>
                  <div className="flex flex-col  gap-2">
                    <div className={`text-sm ${payment.payStatus === 'Completed' ? 'text-[#90EE90]' : 'text-black'}`}>
                      {payment.payStatus}
                    </div>
                    <div className={`text-sm ${payment.payStatus === 'Completed' ? 'text-[#90EE90]' : 'text-black'}`}>
                     Payment Id: {payment.
paymentId}
                    </div>
                    {
payment.qrCodeId && (
                    <div className={`text-sm ${payment.payStatus === 'Completed' ? 'text-[#90EE90]' : 'text-black'}`}>
                     Qr code Id: {payment.
qrCodeId}
                    </div>
                )}  
                    {
payment.
paymentLinkId && (
                    <div className={`text-sm ${payment.payStatus === 'Completed' ? 'text-[#90EE90]' : 'text-black'}`}>
                     Payment link Id: {payment.

paymentLinkId}
                    </div>
                )}  
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div> 
<div className="flex justify-center p-4 ">

      <Calendar
              tileClassName={({ date }) => {
                const dateString = date.toISOString().split("T")[0];
                if (user.attendance.presentDates.includes(dateString)) {
                  return "bg-[#90EE90]"; // Green for present days
                }
                if (user.attendance.absentDates.includes(dateString)) {
                    return "bg-[#FF0000]"; // Red for absent days
                }
                return null;
              }}
            />

</div>
      
         


        {/* Footer */}
        {/* <div className="p-4 border-t">
          <button
            onClick={onClose}
            className="w-full bg-blue-500 hover:bg-blue-600 text-white py-2 rounded-md transition-colors"
          >
            OK
          </button>
        </div> */}
      </div>
    </div>
  )
}
